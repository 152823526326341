<template>
  <div>
    <b-modal ref="add-inventory-check-modal" title="Tạo phiếu kiểm kê" hide-footer>
      <b-card bg-variant="light" text-variant="dark" class="mb-5">
        <b-card-text>
          <b-form>
            <b-form-group label="Cửa hàng:" label-for="store">
              <Autosuggest :model="searchStore" :disabled="disabled" :suggestions="filteredOptionsStore" @change="onInputChange"
                @select="onSelectedStore" size="sm" suggestionName="name" placeholder="tên cửa hàng" />
              <b-form-invalid-feedback id="input-store-live-feedback" :state="validateState('searchStore')">Vui lòng nhập
                cửa hàng</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Chọn loại sản phẩm:" label-for="product-type">
              <b-form-select id="product-type" size="sm" v-model="selectedProductType" :options="listProductType"
                value-field="id" text-field="name" class="select-style"></b-form-select>
            </b-form-group>
          </b-form>
        </b-card-text>
      </b-card>
      <hr />
      <div class="d-flex justify-content-end">
        <b-button class="mr-3" @click="hideModal()">Hủy</b-button>
        <b-button variant="primary" @click="submitForm()">Tạo</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { required } from 'vuelidate/lib/validators';
import { cloneDeep } from '@/utils/common';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';

export default {
  data() {
    return {
      filteredOptionsStore: [],
      optionsStore: [],
      selectedClassStore: null,
      searchStore: '',
      disabled: false,
      selectedProductType: null,
      shortNameStore: '',
      listProductType: [
        { id: null, name: 'Tất cả' },
        { id: 1, name: 'Sản phẩm' },
        { id: 2, name: 'Sản phẩm theo IMEI' },
      ],
      items: [],
    };
  },
  created() {
    // this.fetchStore();
    this.fetchStoreByUser();
  },
  validations: {
    searchStore: {
      required,
    },
  },
  methods: {
    showModal() {
      this.$refs['add-inventory-check-modal'].show();
    },
    hideModal() {
      this.$refs['add-inventory-check-modal'].hide();
    },
    async submitForm() {
      if (!this.searchStore) {
        makeToastFaile('Vui lòng chọn cửa hàng!');
        return;
      }
      this.onLoading = true;
      this.filterdOptions = cloneDeep(this.optionStores);
      const data = {
        storeId: this.selectedClassStore,
        storeName: this.searchStore,
        productType: this.selectedProductType,
        shortNameStore: this.shortNameStore,
      };
      ApiService.post('productStockCheck', data)
        .then(({ data }) => {
          this.$emit('fetchInventoryChecks');
          this.hideModal();
          makeToastSuccess(data.message);
        })
        .catch((error) => {
          makeToastFaile(error.response.data.message);
        });
    },
    fetchStore: async function () {
      this.optionsStore = [];
      ApiService.setHeader();
      ApiService.get('/stores/getStores').then((response) => {
        const stores = response.data.data;
        this.optionsStore = stores;
        this.filteredOptionsStore = stores;
      });
    },
    fetchStoreByUser() {
      ApiService.setHeader();
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          const stores = data.data.stores;
          if (stores.length === 1) {
            this.selectedClassStore = stores[0].id;
            this.searchStore = stores[0].name;
            this.shortNameStore = stores[0].shortName;
            this.storeName = stores[0].storeName;
            this.disabled = true;
          } else {
            this.optionsStore = stores;
            this.filteredOptionsStore = stores;
          }
        }
      });
    },
    onSelectedStore(option) {
      this.searchStore = option.item.name;
      this.selectedClassStore = option.item.id;
      this.shortNameStore = option.item.shortName;
    },
    validateState(name) {
      if (this.isSubmit) {
        const { $dirty, $error } = this.$v[name];
        return $dirty ? !$error : null;
      }
    },
    onInputChange(text) {
      if (text === '') {
        this.filteredOptionsStore = this.optionsStore;
      }
      this.searchStore = text;
      const filteredData = this.optionsStore
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptionsStore = filteredData;
    },
  },
  components: { Autosuggest },
};
</script>

<style>
.input-style {
  border: 1px solid #ced4da;
}

.stock-info .card-body {
  padding: 1rem;
}

.form-group label {
  font-weight: 500;
}
</style>
