<template>
  <span class="label font-weight-bold label-lg label-inline" v-bind:class="bindingClassLabel()"
    style="width: max-content">{{ statusName }}</span>
</template>

<script>
import { PRODUCT_STOCK_CHECK_STATUS } from '@/utils/enum';


export default {
  props: ['status', 'statusName'],
  data() {
    return {
      PRODUCT_STOCK_CHECK_STATUS,
    };
  },
  methods: {
    bindingClassLabel() {
      return {
        'label-light-primary':
          this.status === PRODUCT_STOCK_CHECK_STATUS.NEW,
        'label-light-success':
          this.status === PRODUCT_STOCK_CHECK_STATUS.DONE,
        'label-light-warning':
          this.status === PRODUCT_STOCK_CHECK_STATUS.STOCK_DIFF || this.status === PRODUCT_STOCK_CHECK_STATUS.COMFIRM,
        'label-light-danger':
          this.status === PRODUCT_STOCK_CHECK_STATUS.STOCK_DEBT,
      }
    }
  },
  created() {
  },
};
</script>
